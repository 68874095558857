import * as React from 'react';

import {
    StaticQuery,
    graphql
} from 'gatsby';
import ReactMarkdown from 'react-markdown';

import { CustomHeader } from '../../components/shared/custom-header/custom-header.component';
import { isUndOrEmpty } from '../../utils/string-utils';
import * as styles from './index.module.css';

interface PolitiqueConfidentialiteProps {
    content: string;
}

const politiqueConfidentialitePage: React.FC<PolitiqueConfidentialiteProps> =
    ({ content }: PolitiqueConfidentialiteProps) => {
        return (
            <>
                <CustomHeader transparent={false} dark={false} />
                <div className={styles.politiqueConfidentialitePage}>
                    {!isUndOrEmpty(content) && <ReactMarkdown>{content}</ReactMarkdown>}
                </div>
            </>
        );
    };

const PolitiqueConfidentialiteEnhancer = (Component: React.ComponentType<PolitiqueConfidentialiteProps>) => {
    return (
        <StaticQuery<{ strapiPolitiqueDeConfidentialite: PolitiqueConfidentialiteProps }>
            query={graphql`
				query PolitiqueDeConfidentialiteQuery {
					strapiPolitiqueDeConfidentialite {
						content
					}
				}
				`
            }
            render={data => <Component {...data.strapiPolitiqueDeConfidentialite} />}
        />
    );
};

function PolitiqueConfidentialitePage() {
    return PolitiqueConfidentialiteEnhancer(politiqueConfidentialitePage);
}

export default PolitiqueConfidentialitePage;

export { Head } from '../../components/head/head.component';